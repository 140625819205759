/**
 * GET_WILD_PRICE_USD action types
 */
export const GET_WILD_PRICE_USD_REQUEST = 'GET_WILD_PRICE_USD_REQUEST';
export const GET_WILD_PRICE_USD_SUCCESS = 'GET_WILD_PRICE_USD_SUCCESS';
export const GET_WILD_PRICE_USD_ERROR = 'GET_WILD_PRICE_USD_ERROR';

/**
 * GET_LOOT_PRICE_USD action types
 */
export const GET_LOOT_PRICE_USD_REQUEST = 'GET_LOOT_PRICE_USD_REQUEST';
export const GET_LOOT_PRICE_USD_SUCCESS = 'GET_LOOT_PRICE_USD_SUCCESS';
export const GET_LOOT_PRICE_USD_ERROR = 'GET_LOOT_PRICE_USD_ERROR';

/**
 * GET_WILD_PRICE_PERCENTAGE action types
 */
export const GET_WILD_PRICE_PERCENTAGE_CHANGE_REQUEST =
	'GET_WILD_PRICE_PERCENTAGE_CHANGE_REQUEST';
export const GET_WILD_PRICE_PERCENTAGE_CHANGE_SUCCESS =
	'GET_WILD_PRICE_PERCENTAGE_CHANGE_SUCCESS';
export const GET_WILD_PRICE_PERCENTAGE_CHANGE_ERROR =
	'GET_WILD_PRICE_PERCENTAGE_CHANGE_ERROR';
