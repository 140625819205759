export const TABLE_HEADERS = [
	{
		label: '',
		accessor: '',
		className: '',
	},
	{
		label: 'Pool',
		accessor: '',
		className: '',
	},
	{
		label: 'Date Staked',
		accessor: '',
		className: '',
	},
	{
		label: 'Amount',
		accessor: '',
		className: '',
	},
	{
		label: 'Unlock Date',
		accessor: '',
		className: '',
	},
];
